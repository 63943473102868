body{
  background-color: hsl(207, 26%, 17%);
  font-family: 'Nunito Sans', sans-serif;
}
.body-light{
  background-color: white;
}
.header-dark{
  background-color: hsl(209, 23%, 22%);
  color:white;
}
.header-light{
  background-color: white;
  color:black;
}
.remove-margin{
  margin:0px
}
.dark-input{
  background-color:  hsl(209, 23%, 22%);
  border: none;
  color:white;
  background: url("/src/search-3-24.ico") no-repeat 1px 1px;
  padding-left:30px;
}
.light-input{
  background-color:  white;
  border: 1px solid #1c1c1c;
  color:hsl(209, 23%, 22%);
  background: url("https://img.icons8.com/material-outlined/24/000000/search--v2.png") no-repeat 1px 1px;
  padding-left:30px
}
.card-body-dark{
  background-color:  hsl(209, 23%, 22%);
  border: none;
  color:white;
}
.card-body-light{
  background-color:  white;
  border: none;
  color:sl(209, 23%, 22%);
}
.card-head{
  font-size: 22px;
}
.countryDetails{
  color:white
}
.countryDetailImage{
  height: 300px;
}
.space-even{
  justify-content: space-evenly;
}